body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    main {
        flex-grow: 1;
    }
}

.page-section {
    padding-top: map-get($map: $spacers, $key: 3x);
    padding-bottom: map-get($map: $spacers, $key: 3x);
}
