// SET HEIGHT FOR HEADER TO REMOVE JIGGLE EFFECT WHEN MAKING LOGO SMALLER
// TODO: FIND BETTER SOLUTION
.header {
    --header-height: 8rem;
    height: var(--header-height);
    width: 100%;
    display: block;
    position: relative;
}

.header__inner-container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: var(--header-height);
    z-index: 1000;
}

.header__logo {
    height: calc(calc(var(--header-height) + 5vw) / 2.5);
    width: auto;
    transition: height ease-in-out 0.3s;
}

.header__logo--moved {
    height: calc(calc(var(--header-height) + 5vw) / 3);
}
