.blob-container {
    width: 100vw;
    transform: translateX(0);
    background-color: $secondary;
}

.contact__icon {
    width: 1rem;
    height: auto;
    text-align: center;
}

.contact__headline {
    background-color: $secondary;
}

.contact__form-block {
    margin-bottom: -3rem;
}

.contact__form-block .form-control {
    background-color: $gray;
}

.contact__headline-block {
    text-align: center;
}

.contact__form-block,
.contact__headline-block {
    position: relative;
}

.contact__form-block::before,
.contact__headline-block::before {
    content: '';
    position: absolute;
    top: 0;
    right: -9999px;
    bottom: 0;
    left: -9999px;
    background-color: $white;
    z-index: -1;
}

.contact__text-block {
    order: -1;
}

@include media-breakpoint-up(lg) {
    .contact__headline {
        background-color: $white;
    }

    .contact__form-block {
        margin-bottom: 0rem;
    }

    .contact__form-block .form-control {
        background-color: $white;
    }

    .contact__headline-block {
        text-align: start;
    }

    .contact__text-block {
        order: unset;
        text-align: unset;
    }

    .contact__form-block::before,
    .contact__headline-block::before {
        background-color: unset;
    }
}

@include media-breakpoint-up(xl) {
    .blob-container {
        background-color: transparent;
        width: 200vw;
        transform: translateX(-25%);

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-image: url('/public/img/blob-orange.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transform: scale(1.15);
            z-index: -1;
        }
    }
}
