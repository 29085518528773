// Variant solid
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @if $color == 'primary' {
            @include button-variant(
                $background: $primary,
                $color: $btn-color,
                $hover-color: $btn-hover-color,
                $border: $value,
                $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
                $hover-border: tint-color($value, $btn-hover-border-tint-amount),
                $active-background:
                    tint-color($value, $btn-active-bg-tint-amount),
                $active-border:
                    tint-color($value, $btn-active-border-tint-amount),
                $active-color: $btn-color
            );
        } @else if $color == 'secondary' {
            @include button-variant(
                $background: $secondary,
                $color: $btn-color,
                $hover-color: $btn-hover-color,
                $border: $value,
                $hover-background: tint-color($value, $btn-hover-bg-tint-amount),
                $hover-border: tint-color($value, $btn-hover-border-tint-amount),
                $active-background:
                    tint-color($value, $btn-active-bg-tint-amount),
                $active-border:
                    tint-color($value, $btn-active-border-tint-amount),
                $active-color: $btn-color
            );
        }
    }
}

// Variant outline
@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant(
            $value,
            $color-hover: $btn-color,
            $active-background: $value,
            $active-border: $value,
            $active-color: $btn-color
        );
    }
}
