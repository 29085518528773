body {
    font-family:
        Open Sans,
        ui-sans-serif,
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Helvetica Neue,
        Arial,
        Noto Sans,
        sans-serif,
        Apple Color Emoji,
        Segoe UI Emoji,
        Segoe UI Symbol,
        Noto Color Emoji;
}

.headlie-hero {
    @include font-size($h1-font-size);
    margin-bottom: map-get($map: $spacers, $key: 2x);
}

.headline-section {
    @include font-size($h2-font-size);
    margin-bottom: map-get($map: $spacers, $key: 3x);
}
