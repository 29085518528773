/* open-sans-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src:
        url('../../../../public/fonts/open-sans-v35-latin-regular.woff2')
            format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
            url('../../../../public/fonts/open-sans-v35-latin-regular.ttf')
            format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src:
        url('../../../../public/fonts/open-sans-v35-latin-italic.woff2')
            format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
            url('../../../../public/fonts/open-sans-v35-latin-italic.ttf')
            format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src:
        url('../../../../public/fonts/open-sans-v35-latin-700.woff2')
            format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
            url('../../../../public/fonts/open-sans-v35-latin-700.ttf')
            format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-700italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src:
        url('../../../../public/fonts/open-sans-v35-latin-700italic.woff2')
            format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
            url('../../../../public/fonts/open-sans-v35-latin-700italic.ttf')
            format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
