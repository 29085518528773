// Base Colors
$primary: #7bbec7;
$secondary: #ffe1c5;
$tertiary: #e55100;
$gray: #f7f8f9;
$white: #ffffff;
$black: #000000;

$custom-theme-colors: (
    'tertiary': $tertiary,
    'gray': $gray,
);

// Container Max Widths
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1200px,
);

// Spacers
$spacer: 1rem;
$spacers: (
    0: 0,
    xs: $spacer * 0.25,
    sm: $spacer * 0.5,
    md: $spacer,
    lg: $spacer * 1.5,
    2x: $spacer * 2,
    3x: $spacer * 3,
    4x: $spacer * 4,
    5x: $spacer * 5,
    6x: $spacer * 6,
    7x: $spacer * 7,
    8x: $spacer * 8,
    9x: $spacer * 9,
    10x: $spacer * 10,

    section: $spacer * 3,
);

// CSS Grid
$enable-cssgrid: true;

// Body
$body-color: $black;

// Buttons
$btn-color: $white;
$btn-hover-color: $white;

$input-btn-padding-x: $spacer * 1.5;

// Links
$link-color: $body-color;

// Navbar
$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;
$navbar-toggler-focus-width: 0;
$navbar-light-color: $body-color;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;

// Border Radius
$border-radius: 0.625rem;
$border-radius-sm: 1.25rem;
$border-radius-lg: 2.5rem;
$border-radius-xl: 5rem;
$border-radius-xxl: 10rem;

// Font Size
$h1-font-size: 2.75rem;
$h2-font-size: 2.25rem;
$h3-font-size: 2rem;
$h4-font-size: 1.75rem;
$h5-font-size: 1.5rem;
$h6-font-size: 1.25rem;

$font-size-base: 0.9rem;
$line-height-base: 2;

// Font Weight
$headings-font-weight: 400;

// Input
$input-bg: $white;
$input-border-color: $white;
$input-placeholder-color: $black;
