.contact-banner {
    display: grid;
    grid-template-columns: 5fr 7fr;
    grid-template-rows: 1fr 3fr;
}

.contact-banner__ghost-element {
    position: relative;
    grid-column: 1;
    grid-row: 1 / 2;
}

.contact-banner__ghost-element::before {
    content: '';
    position: absolute;
    top: 0;
    right: -99999px;
    bottom: 0;
    left: -99999px;
    background-color: var(--bs-body-bg);
}

.contact-banner__text-container {
    padding: 3rem 0;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    z-index: 1;
}

.contact-banner__second-ghost-element {
    position: relative;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}

.contact-banner__second-ghost-element::after {
    content: '';
    position: absolute;
    top: 0;
    right: -99999px;
    bottom: 0%;
    left: -10%;
    background-image: url('/public/img/blob-blue.svg');
    background-repeat: no-repeat;
}

.contact-banner__image-container {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
}

@include media-breakpoint-up(lg) {
    .contact-banner {
        grid-template-rows: auto auto;
    }

    .contact-banner__image-container {
        grid-column: 2;
    }

    .contact-banner__text-container {
        grid-column: 1;
    }

    .contact-banner__second-ghost-element {
        display: block;
        grid-column: 2;
    }

    .contact-banner__second-ghost-element::after {
        left: 0;
        bottom: 10%;
    }
}
