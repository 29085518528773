textarea.form-control {
    min-height: 7.5rem;
}

.form-control::placeholder {
    color: gray;
}

// Basic honyepot styling, needs better implementation in Dummy
label[for="dynamic_form2_name"],
input#dynamic_form2_name {
    display: none;
}
