.nav-btn-link {
    margin: auto 0;
}

.navbar__contact-button {
    padding: map-get($map: $spacers, $key: sm) map-get($map: $spacers, $key: md);
    line-height: 1;
    border-radius: 5px;

    .icon {
        font-size: $h5-font-size;
    }
}

@include media-breakpoint-down(sm) {
    .navbar__contact-button {
        padding-right: calc(map-get($map: $spacers, $key: md) + ($container-padding-x / 2));
        margin-right: calc(($container-padding-x / 2) * -1);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

@include media-breakpoint-down(md) {
    .nav-btn-link {
        background-color: unset;
        color: $black;
        border: none;
        padding: map-get($map: $spacers, $key: sm) 0;
    }

    .nav-btn-link:hover {
        background-color: unset;
        color: $primary;
    }
}

@include media-breakpoint-up(md) {
    .navbar-nav {
        --#{$prefix}navbar-nav-link-padding-x: 0;
        --#{$prefix}nav-link-padding-y: 0;
        gap: calc(map-get($map: $spacers, $key: 'lg') * 2); // For some reason spacers 3x returns nothing

        .nav-item {
            align-items: center;
            display: flex;
        }
    }


    .navbar__contact-button {
        display: none;
    }
}
