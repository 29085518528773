.footer-main {
    padding-top: map-get($map: $spacers, $key: lg);
    padding-bottom: map-get($map: $spacers, $key: lg);

    p {
        margin-bottom: 0;
    }
}

.footer-main__row {
    justify-content: space-around;
}

@include media-breakpoint-up(md) {
    .footer-main__row {
        justify-content: space-between;
    }
}
