$waveHeight: 9vw;

#ueber-uns {
    position: relative;
    margin-bottom: $waveHeight;
    padding-top: map-get($map: $spacers, $key: 7x);
    padding-bottom: map-get($map: $spacers, $key: 7x);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: $waveHeight;
        background-image: url('/public/img/welle-oben.svg');
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        transform: translateY(-99%);
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: $waveHeight;
        background-image: url('/public/img/welle-unten.svg');
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        transform: translateY(
            99%
        ); //! Weird line when 100%? Checked on Chrome and Firefox
    }
}
